<template>
  <tr :class="{ tr_select: isChecked }">
    <td>
      <div class="box_choice type_alone">
        <div class="item_choice">
          <!-- disabled 상태시 disabled 클래스 추가 -->
          <input
            :id="rowData.requestCid"
            v-model="checkedNamesSync"
            type="checkbox"
            class="inp_choice"
            name="projectCode"
            :value="rowData"
          />
          <label class="lab_choice" :for="rowData.requestCid">
            <span class="ico_account ico_check" />
          </label>
        </div>
      </div>
    </td>
    <td>{{ rowData.requestCid }}</td>
    <td>{{ rowData.title }}</td>
    <td>{{ rowData.catagorys }}</td>
    <td>{{ rowData.productNm }}</td>
  </tr>
</template>

<script>
export default {
  name: "PopPurchaseRequestLine",
  props: {
    rowData: Object,
    checkedNames: Array,
  },
  computed: {
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
    isChecked() {
      return this.checkedNames.find((item) => item.requestCid == this.rowData.requestCid);
    },
  },
};
</script>
